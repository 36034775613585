import React from 'react';
import { graphql } from 'gatsby';
import { CopyPageSection } from '../styles/CopyPageStyles'
import CopyBanner from "../sections/copywritingpage/CopyBanner";
import CopyIntro from "../sections/copywritingpage/CopyIntro";
import CopyBlocks from "../sections/copywritingpage/CopyBlocks";
import ContactForm from "../sections/contactPage/ContactForm";
import Footer from "../components/Footer";

export default function DevelopmentPage({ data, pageContext }) {
  return (
    <>
      <CopyPageSection>
        <CopyBanner data={data.sanityCopyBanner} pageContext={pageContext.locale} />
        <CopyIntro data={data.sanityCopyIntro} pageContext={pageContext.locale} />
        <div className="divider"></div>
        <CopyBlocks data={data.sanityCopyBlocks} pageContext={pageContext.locale} />
        <div className="divider"></div>
        <ContactForm data={data.sanityContactForm} pageContext={pageContext.locale} grid={'row'} />
        <Footer data={data.sanityFooter} pageContext={pageContext.locale}/>
      </CopyPageSection>
    </>
  );
}
export const query = graphql`
  query CopywritingQuery  {
    sanityCopyBanner {
      title {
        en
        fr
      }
      subtitle {
        en
        fr
      }
      image {
        asset {
          fluid(sizes: "(max-width: 2400px) 100vw, 2400px") {
              ...GatsbySanityImageFluid
            }
        }
      }
    }
    sanityCopyIntro {
      description {
        en
        fr
      }
      title {
        en
        fr
      }
      subtitle {
        en
        fr
      }
    }
    sanityCopyBlocks {
      title {
        en
        fr
      }
      copy_blocks_list {
        title {
          en
          fr
        }
        text {
          en
          fr
        }
        image {
          asset {
            fluid(sizes: "(max-width: 800px) 800px") {
                ...GatsbySanityImageFluid
              }
          }
        }
      }
    }
    sanityContactForm {
      title {
        en
        fr
      }
      description {
          en
          fr
      }
      regulations {
        en {
          _rawChildren
        }
        fr {
          _rawChildren
        }
        _rawEn(resolveReferences: {maxDepth: 100})
        _rawFr(resolveReferences: {maxDepth: 100})
      }
    }
    sanityFooter {
      phone
      email
      address_line1
      address_line2
      social_media_list {
        website
        link
      }
      footer_nav_list {
        link
        title {
          en
          fr
        }
      }
    }
  }
`;